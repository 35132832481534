<template>
	<div id="contact-root">
		<img src="@/images/pido_white.png" alt="" class="icon">
		<div class="buttons">
			<img src="@/images/instagram-fill.png" alt="" @click.stop="toUrl('https://www.instagram.com/pido_official_account?igsh=eTI1cTZsMDN5dGg3')">
			<img src="@/images/x.png" @click.stop="toUrl('https://twitter.com/Pido_OfficialX')">
			<img src="@/images/discord.png" @click.stop="toUrl('https://discord.com/invite/TZ4V9uQEmX')">
			<img src="@/images/xiaohongshu.png" @click.stop="toUrl('https://www.xiaohongshu.com/user/profile/65aae734000000001302b225?xhsshare=CopyLink&appuid=65aae734000000001302b225&apptime=1706487141')">
		</div>

		<span class="whitepaper" @click.stop="toUrl('/_book/index.html')">
			Whitepaper
		</span>
		<div class="mail" @click.stop="copyToClipboard('info@pido.cc')">
			info@pido.cc
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {

			copyToClipboard(textToCopy) {
				this.$message('Email has copied to your clipboard');
				// navigator clipboard 需要https等安全上下文
				if (navigator.clipboard && window.isSecureContext) {
					// navigator clipboard 向剪贴板写文本
					return navigator.clipboard.writeText(textToCopy);
				} else {
					// 创建text area
					let textArea = document.createElement("textarea");
					textArea.value = textToCopy;
					// 使text area不在viewport，同时设置不可见
					textArea.style.position = "absolute";
					textArea.style.opacity = 0;
					textArea.style.left = "-999999px";
					textArea.style.top = "-999999px";
					document.body.appendChild(textArea);
					textArea.focus();
					textArea.select();
					return new Promise((res, rej) => {
						// 执行复制命令并移除文本框
						document.execCommand('copy') ? res() : rej();
						textArea.remove();
					});
				}
			},
			toUrl(url) {
				window.open(url, "_blank")
			}

		}
	}
</script>

<style scoped lang="scss">
	#contact-root {
		height: 100%;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.2);
		position: absolute;
		top: 0;
		z-index: 2;
		transition: opacity var(--animationTime) ease-in-out;

		img.icon {
			position: absolute;
			width: var(--imageSize);
			height: var(--imageSize);
			top: 40%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
		}

		.buttons {
			position: absolute;
			top: calc(45% + var(--imageSize)/2);
			left: 50%;
			transform: translateX(-50%);
			display: flex;

			img {
				width: var(--iconSize);
				height: var(--iconSize);
				padding: 0 var(--iconSpace);
				cursor: pointer;

			}
		}

		.whitepaper {
			position: absolute;
			top: calc(45% + var(--imageSize)/2 + var(--iconSize)*1.3);
			left: 50%;
			transform: translateX(-50%);
			border-bottom: 1px solid white;
			font-size: 20px;
			cursor: pointer;
		}

		.mail {
			font-size: 20px;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 1em;
			cursor: pointer;
		}
	}
</style>
