<template>
 <div id="info-root">
  <img src="@/images/pido_trans.png" alt="" class="icon">
  <div class="intro">
   <p>* Pido stands as a pioneering Web 3.0-based platform for affordable creations.</p>
   <p>
    * Pido 是一家领先的基于Web 3.0的发现“可得创作品”的平台。</p>
  </div>
 </div>
</template>

<script>
export default {
 data() {
  return {

  }
 },

}
</script>

<style scoped lang="scss">
#info-root {
 height: 100%;
 width: 100%;
 background-color: rgba(0, 0, 0, 0.7);
 position: absolute;
 top: 0;
 z-index: 2;
 transition: opacity var(--animationTime) ease-in-out;

 img.icon {
  position: absolute;
  width: var(--imageSize);
  height: var(--imageSize);
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
 }

 .intro {
  width: 80%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10vh;
  font-size: 16px;
 }
}
</style>