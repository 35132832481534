<template>
	<div id="app" @click.stop="showing = !showing" @mousewheel="showing = !showing"  >
		<div class="bg" :class="{ showing }"></div>
		<InfoPage :class="{ hidden: !showing }" />
		<ContactPage :class="{ hidden: showing }" />
	</div>
</template>

<script>
	import InfoPage from './pages/info.vue'
	import ContactPage from './pages/contact.vue'
	export default {
		name: 'App',
		data() {
			return {
				showing: true
			}
		},
		components: {
			InfoPage,
			ContactPage
		},
		mounted() {
			let vh = window.innerHeight * 0.01
			document.documentElement.style.setProperty('--vh', `${vh}px`)
			window.addEventListener('resize', () => {
				let vh = window.innerHeight * 0.01
				console.log(vh);
				document.documentElement.style.setProperty('--vh', `${vh}px`)
			})
		},
	}
</script>

<style lang="scss">
	* {
		padding: 0;
		margin: 0;
	}

	body {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		user-select: none;
		overflow: hidden;
	}

	#app {
		height: 100vh;
		width: 100vw;
		height: calc(var(--vh, 1vh) * 100);
		position: relative;
		color: white;
		--imageSize: max(15vw, 15vh);
		--iconSize: max(3vw, 3vh);
		--iconSpace: min(2vw, 2vh);
		--animationTime: .5s;
		overflow: hidden;

		.hidden {
			opacity: 0;
			z-index: 1;
		}
	}

	.bg {
		position: absolute;
		top: 0;
		z-index: 1;
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		width: 100vw;
		background-image: url("@/images/background.png");
		background-position: center;
		background-size: cover;
		transition: all var(--animationTime) ease-in-out;
		transform: rotate(45deg) scale(2.4);



		&.showing {
			transform: rotate(0deg) scale(1);
		}
	}
</style>
